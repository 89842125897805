import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const LeadMagnet = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [error, setError] = useState({ name: "", email: "" });
  const [showThankYou, setShowThankYou] = useState(false);
  // const [thankYouMessage, setThankYouMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false); // Start with popup hidden

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({ name: "", email: "" });
    // setThankYouMessage("");

    let isValid = true;

    if (!formData.name) {
      setError((prev) => ({ ...prev, name: "Name is required" }));
      isValid = false;
    }

    if (!formData.email) {
      setError((prev) => ({ ...prev, email: "Email is required" }));
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email format" }));
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      try {
        const response = await axios.post("/api/leadmagnet", formData);
        if (response.status === 200) {
          setShowThankYou(true);
          setFormData({ name: "", email: "" });
          window.location.href =
            "https://strong-list-887.notion.site/Lara-Acosta-s-Sales-Page-Analysis-1795b1f591ba8012a658fcba444458cc?pvs=4";
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.error || "An error occurred. Please try again.";
        setError((prev) => ({ ...prev, email: errorMessage }));
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className={`free-review-page `}>
      <div className="free-review-wrapper">
        <div className="container">
          <Link to="/conversion-catalyst">
            <div className="review-page-logo">
              <img src={require("..//assets/images/logo.png")} alt="logo" />
            </div>
          </Link>
          {showThankYou ? (
            <div className="thank-you-popup">
              <h2>Thank You For submitting!</h2>
              <p>You will be redirected to the full resource in seconds</p>
              <button
                className="thank-you-btn"
                onClick={() => {
                  // setIsOpen(false);
                  window.location.href = "https://strong-list-887.notion.site/Lara-Acosta-s-Sales-Page-Analysis-1795b1f591ba8012a658fcba444458cc?pvs=4";
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="row audit-popup">
              <div className="col-lg-6 col-md-12 image-section">
                <img
                  src={require("..//assets/images/lara'sLeadMagnet.png")}
                  alt="Lead Magnet Illustration"
                />
              </div>
              <div className="col-lg-6 col-md-12 form-section">
                <div className="form-section-description">
                  <h2>Lara Acosta's Sales Page Full Analysis</h2>
                  <p>
                    In this file, you will gain access to Lara's (Literally
                    Academy) high-converting sales page that she uses for her
                    program. You will learn valuable tips on how her page is
                    optimized for conversions, which you can apply to your own
                    website.
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${
                        error.name ? "is-invalid" : ""
                      }`}
                      name="name"
                      placeholder="First Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {error.name && (
                      <div className="invalid-feedback">{error.name}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control ${
                        error.email ? "is-invalid" : ""
                      }`}
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {error.email && (
                      <div className="invalid-feedback">{error.email}</div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-primary">
                    {loading ? "Submitting..." : "Give Me Access Now"}
                  </button>
                  <p style={{ fontSize: "12px", marginTop: "8px" }}>
                    By submitting, you agree to be added to our list to receive
                    valuable information, and exclusive offers.
                  </p>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadMagnet;
