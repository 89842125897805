import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css.map";
import "../assets/styles.css";
import Home from "../pages/Home";
import Newsletter from "../pages/Newsletter";
import GrowthAccelerator from "./GrowthAccelerator";
import CaseStudy from "../components/CaseStudy";
import ConversionCatalyst from "./ConversionCatalyst";
import WebsiteReview from "./WebReview";
import LeadMagnet from "./LeadMagnet";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/growth-accelerator/newsletter" element={<Newsletter />} />
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/conversion-catalyst" element={<ConversionCatalyst />} />
        <Route path="/growth-accelerator" element={<GrowthAccelerator />} />
        <Route path="/free-website-review" element={<WebsiteReview />} />
        <Route path="/linkedin/Laras-Sales-Page-Analysis" element={<LeadMagnet />} />

      </Routes>
    </div>
  );
}

export default App;
