import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    // Reset error messages
    setEmailError("");
    setFnameError("");

    // Validate inputs
    if (!fname.trim()) {
      setFnameError("First name is required.");
      valid = false;
    }
    if (!email.trim()) {
      setEmailError("Email is required.");
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    }

    if (!valid) return;

    try {
      const response = await axios.post("/api/newsletter", { fname, email });
      console.log(response.data.sent);
      if (response.data.sent) {
        setThankYouMessage(
          "You’ve successfully joined the Growth Accelerator Newsletter. Stay tuned for valuable updates!"
        );
        setIsOpen(true);
        setEmail("");
        setFname("");
      } else {
        setEmailError("This email is already subscribed.");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // Determine redirection based on current route
  const isNewsletterRoute = location.pathname === "/newsletter";
  const isGrowthAcceleratorRoute = location.pathname === "/growth-accelerator/newsletter";

  const handleLogoClick = () => {
    if (isNewsletterRoute) {
      navigate("/");
    } else if (isGrowthAcceleratorRoute) {
      navigate("/conversion-catalyst");
    }
  };

  const handleThankYouOk = () => {
    if (isNewsletterRoute) {
      navigate("/");
    } else if (isGrowthAcceleratorRoute) {
      navigate("/conversion-catalyst");
    }
    setIsOpen(false);
  };

  return (
    <div id="newsletter_page">
      <div className="newsletter_wrapper">
        <div className="container">
          <div
            className="newsletter_logo"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          >
            <img src={require("../assets/images/logo.png")} alt="logo" />
          </div>
          {isOpen ? (
            <div className="thank-you-popup">
              <h2>Thank You For Submitting!</h2>
              <p>{thankYouMessage}</p>
              <button className="thank-you-btn" onClick={handleThankYouOk}>
                Ok
              </button>
            </div>
          ) : (
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="page_content">
                  <div className="page_form">
                    <h3 className="newsletter_title">
                      Join The Growth Accelerator Newsletter
                    </h3>
                    <p className="newsletter_description">
                      Valuable strategies delivered straight to your inbox.
                      Learn how to sell more of your offers, optimize your sales
                      page for higher conversions, and grow your personal brand.
                    </p>
                    <div className="newsletter_Form" id="frm_subscribe">
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        name="subscribe"
                        className="d-flex flex-column gap-3"
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            value={fname}
                            className={`form-control ${
                              fnameError ? "is-invalid" : ""
                            }`}
                            onChange={(e) => setFname(e.target.value)}
                            id="fname"
                            placeholder="Enter Your First Name"
                          />
                          {fnameError && (
                            <div className="invalid-feedback">{fnameError}</div>
                          )}
                        </div>

                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            className={`form-control email_news ${
                              emailError ? "is-invalid" : ""
                            }`}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            placeholder="Enter Your Email"
                          />
                          {emailError && (
                            <div className="invalid-feedback">{emailError}</div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary subscribeBtn"
                        >
                          Join The Newsletter
                        </button>
                      </form>
                      <p className="form_note">
                        We will make sure to only send you valuable content to
                        your inbox.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="newsletter_footer">
        <p className="copyrights">2024 Growwithalaa. All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Newsletter;
