import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Services";
import WebsiteReviewPopup from "../components/WebsiteReviewPopup";
import ConversionCatalystQuestions from "../components/ConversionCatalystQuestions";
import HeroVSL from "../assets/images/Sales-Page-Hero-Short.svg";
function ConversionCatalyst() {
  const style1 = {
    position: "relative",
    padding: "56.25% 0 0 0",
  };
  const style2 = {
    position: "absolute",
    top: 0,
    borderRadius: "12px",
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <div>
      <Header />
      <div id="#home_page" className="conversion_catalyst_page">
        <div className="home_page_wrapper">
          <div className="hero_section conversion_hero_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="hero_content">
                    <div className="sub-title">
                      <p className="sub-title-description">
                        Personal brands, Coaches and Consultants
                      </p>
                    </div>
                    <h1 className="hero_title">
                      Maximize Your Sales Page Conversion In 10 Days Or{" "}
                      <span className="purple_word">Your Money Back</span>
                    </h1>
                    <p className="hero_description">
                      Stop losing valuable clients due to an underperforming
                      sales page. We’ll optimize it or build it from scratch to
                      boost conversions.Guaranteed!
                    </p>
                    <a href="#funnel_questions">
                      <button className="cta herobtn">
                        Let's build my sales page
                        <img
                          src={require("..//assets/images/arrow.png")}
                          alt="arrow"
                        />
                      </button>
                    </a>
                    <p className="button-caption">
                      Book your free consultation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="half_line"></div>
          <div className="vsl">
            <div className="container">
              <div className="static_vsl">
                <img
                  src={HeroVSL}
                  alt="Static Vsl"
                />
              </div>
            </div>
          </div>

          <div className="half_line"></div>

          <div className="services">
            <div className="container">
              <div className="row">
                <div className="services_content">
                  <h2 className="services_title">What's Included</h2>
                  <p className="services_subtitle">
                    Check out what'll you get when you purchase the
                    conversion-catalyst system
                  </p>
                </div>
                <div className="col-lg-4 mt-3">
                  <Service
                    icon={`${require("..//assets/images/salespage.png")}`}
                    serviceName="High Converting Sales Page"
                    serviceDescription="Transform leads into loyal customers with a persuasive and results-driven sales page design"
                  />
                </div>
                <div className="col-lg-4 mt-3">
                  <Service
                    icon={`${require("..//assets/images/scriptvsl.png")}`}
                    serviceName="High Converting VSL Script"
                    serviceDescription="Engage and persuade viewers with expertly crafted Video Sales Letter scripts that drive action."
                  />
                </div>
                <div className="col-lg-4 mt-3">
                  <Service
                    icon={`${require("..//assets/images/editingvsl.png")}`}
                    serviceName="High Quality VSL Editing"
                    serviceDescription="Maximize viewer engagement and conversions with professional Video Sales Letter editing expertise."
                  />
                </div>
                <div className="col-lg-4 mt-3 mt-lg-4">
                  <Service
                    icon={`${require("..//assets/images/offeroptimization.png")}`}
                    serviceName="Offer Optimization"
                    serviceDescription="Maximize ROI with expert offer optimization strategies and tactics. We'll craft an irresistible offer using our proven strategies."
                  />
                </div>
                <div className="col-lg-4 mt-3 mt-lg-4">
                  <Service
                    icon={`${require("..//assets/images/seovsl.png")}`}
                    serviceName="SEO"
                    serviceDescription=" Improve your website's visibility organically. Our SEO service enriches content, refines keywords, and employs strategies to boost rankings and drive targeted traffic."
                  />
                </div>
                <div className="col-lg-4 mt-3 mt-lg-4">
                  <Service
                    icon={`${require("..//assets/images/customersupport.png")}`}
                    serviceName="24/7 Support"
                    serviceDescription="Experience uninterrupted support with our team ready to answer all your questions and inquiries round the clock."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="video_testimonials">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="services_content">
                    <h2 className="services_title">
                      Check Out What Previous{" "}
                      <span className="purple_color">Clients Said</span>
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-10 m-auto">
                      <div className="videotestimonial_card">
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="video_thumbnail">
                              <div className="thumbnail">
                                <div style={style1}>
                                  <iframe
                                    src="https://player.vimeo.com/video/926173215?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={style2}
                                    title="Verse Digital"
                                  ></iframe>
                                </div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="text">
                              <p className="text_testimonial">
                                "Previously, I did not have a website that meets
                                my expectations until I collaborated with Alaa.
                                Their creation of a professional website for my
                                agency exceeded my expectations, delivering
                                exceptional results. I highly recommend working
                                with him."
                              </p>
                              <div className="text_box">
                                <p className="text_speaker">
                                  Rachel AlHoujairy
                                </p>
                                <p className="text_speaker_position">
                                  Founder at Verse Digital
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 mt-5">
                      <div className="videotestimonial_card">
                        <div className="text">
                          <p className="text_testimonial">
                            "Working with Alaa was amazing and beyond
                            expectations; the designs were excellent, all
                            looking flawless.
                            <br />
                            All of that was achieved for amazing prices."
                          </p>
                          <div className="text_box">
                            <p className="text_speaker">Nadeem Ghaddar</p>
                            <p className="text_speaker_position">
                              Founder of IsItKissable
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mt-5">
                      <div className="videotestimonial_card">
                        <div className="text">
                          <p className="text_testimonial">
                            "Working with Alaa was a Great experience,he was
                            able to deliver what he promised on time and the
                            results were great.On Top of that I was amazed with
                            how fast he responds to our questions."
                          </p>
                          <div className="text_box">
                            <p className="text_speaker">Wael Hammad</p>
                            <p className="text_speaker_position">
                              Founder Of Hiking Lebanon
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-10 m-auto">
                      <div className="videotestimonial_card">
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="video_thumbnail">
                              <div className="thumbnail">
                                <div style={style1}>
                                  <iframe
                                    src="https://player.vimeo.com/video/924151734?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={style2}
                                    title="Bena Social"
                                  ></iframe>
                                </div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="text">
                              <p className="text_testimonial">
                                "We worked with Alaa on our website, and the
                                results were great. He delivered what he
                                promised in a very short time, from design to
                                development and SEO. I highly recommend working
                                with him."
                              </p>
                              <div className="text_box">
                                <p className="text_speaker">Nagham Yehya</p>
                                <p className="text_speaker_position">
                                  co-Founder of Bena Social
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 mt-5">
                      <div className="videotestimonial_card">
                        <div className="text">
                          <p className="text_testimonial">
                            "I recently dealt with Alaa on a project, and the
                            experience was exceptional. His expertise guided us
                            through every stage of development and social media
                            campaign went viral.Highly recommended!"
                          </p>
                          <div className="text_box">
                            <p className="text_speaker">Alaa Yehya</p>
                            <p className="text_speaker_position">
                              co-Founder of Hadi's Bookstore
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mt-5">
                      <div className="videotestimonial_card">
                        <div className="text">
                          <p className="text_testimonial">
                            "Alaa has helped me through 95% of my projects for
                            my agency.
                            <br />
                            <br />
                            He is amazing "
                          </p>
                          <div className="text_box">
                            <p className="text_speaker">Hussien Sleiman</p>
                            <p className="text_speaker_position">
                              Founder of Majestic Media
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="funnelQuestions" id="funnel_questions">
            <div className="container">
              <div className="services_content">
                <h2 className="services_title">
                  Let's Help You Double{" "}
                  <span className="purple_color">Your Sales</span>
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-8 flex-column d-flex justify-content-center align-items-center mx-auto">
                  <ConversionCatalystQuestions />
                </div>
              </div>
            </div>
          </div>
          <div className="half_line"></div>
          <div className="book_a_call" id="call">
            <div className="services_content">
              <h2 className="services_title">
                Book Your Free Call <span className="purple_color">Today</span>
              </h2>
            </div>

            <div
              className="calendly-inline-widget calendly"
              data-url="https://calendly.com/alaaezdine/30min"
            ></div>
            <script
              type="text/javascript"
              src="https://assets.calendly.com/assets/external/widget.js"
              async
            ></script>
          </div>
          <div className="half_line"></div>
          <Footer />
        </div>
      </div>
      <WebsiteReviewPopup />
    </div>
  );
}

export default ConversionCatalyst;
