import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/alaaezdine/30min",
      });
    }
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const isConversionCatalystRoute = location.pathname === "/conversion-catalyst";
  // const isHomeRoute = location.pathname === "/" || location.pathname === "/home";

  const newsletterLink = isConversionCatalystRoute
    ? "/growth-accelerator/newsletter"
    : "/newsletter";

  return (
    <div id="footer">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="footer_slogan">
                Trust us to build your success online.
              </h2>
              <div className="footer_wrapper">
                <div className="logo_div">
                  <div className="logo_div_footer">
                    <img
                      src={require("../assets/images/logo.png")}
                      alt="footer logo"
                    />
                  </div>
                </div>

                <div className="footer_links">
                  <ul className="footer_list">
                    {!isConversionCatalystRoute && (
                      <Link to="/" className="link_item" onClick={handleLogoClick}>
                        <li>Home</li>
                      </Link>
                    )}
                    <Link to={newsletterLink} className="link_item" onClick={handleLogoClick}>
                      <li>Newsletter</li>
                    </Link>
                    {!isConversionCatalystRoute && (
                      <Link
                        to="/conversion-catalyst"
                        className="link_item"
                        onClick={handleLogoClick}
                      >
                        <li>Conversion Catalyst Program</li>
                      </Link>
                    )}
                    <Link to="/growth-accelerator" className="link_item" onClick={handleLogoClick}>
                      <li>I Need Assistance</li>
                    </Link>
                  </ul>
                </div>

                <div className="footer_cta">
                  <button
                    className="cta footerbtn"
                    onClick={handleScheduleClick}
                  >
                    Reserve My Spot!
                  </button>
                </div>
              </div>
              <div className="social_icons">
                <a href="https://www.instagram.com/growwithalaa/">
                  <img
                    src={require("../assets/images/insta.png")}
                    alt="insta logo"
                  />
                </a>
                <a href="https://www.linkedin.com/in/alaaabiezdine/">
                  <img
                    src={require("../assets/images/linkedin.png")}
                    alt="linkedIn logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rights">
        <p className="copyrights">2024 GrowwIthalaa. All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Footer;
