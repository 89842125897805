import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const WebReview = () => {
  const [formData, setFormData] = useState({ website: "", email: "" });
  const [error, setError] = useState({ website: "", email: "" });
  const [showThankYou, setShowThankYou] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Start with popup hidden
  const navigate = useNavigate();
  // Check if the cookie exists on mount
  useEffect(() => {
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("popupSubmitted="));
    if (cookie) {
      setIsOpen(false); // If the popup was already submitted, don't show it
    } else {
      const timer = setTimeout(() => {
        setIsOpen(true); // Show popup after 20 seconds
      }, 20000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateWebsite = (website) => {
    const websiteRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\S*)?$/;
    return websiteRegex.test(website);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({ website: "", email: "" });
    setThankYouMessage("");
    setShowThankYou(false);
    let isValid = true;

    if (!formData.website) {
      setError((prev) => ({ ...prev, website: "Website URL is required" }));
      isValid = false;
    } else if (!validateWebsite(formData.website)) {
      setError((prev) => ({ ...prev, website: "Invalid website URL format" }));
      isValid = false;
    }

    if (!formData.email) {
      setError((prev) => ({ ...prev, email: "Email is required" }));
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email format" }));
      isValid = false;
    }

    if (isValid) {
      setLoading(true); // Start loading
      try {
        const response = await axios.post("/api/web-review-submit", formData);

        if (response.data.sent) {
          setThankYouMessage(
            "Thank you! A full report will be sent your way in the next 24 hours."
          );
          setShowThankYou(true);
          setFormData({ website: "", email: "" }); // Reset form data on success

          // Set a cookie to prevent the popup from showing again for 3 months
          const expiryDate = new Date();
          expiryDate.setMonth(expiryDate.getMonth() + 3); // Cookie expires in 3 months
          document.cookie = `popupSubmitted=true; expires=${expiryDate.toUTCString()}; path=/`;
        } else {
          // Add error message to form errors if response indicates a problem
          if (response.data.error) {
            if (response.data.error.includes("email")) {
              setError((prev) => ({
                ...prev,
                email: response.data.error,
              }));
            }
            if (response.data.error.includes("website")) {
              setError((prev) => ({
                ...prev,
                website: response.data.error,
              }));
            }
          }
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.error || "An unexpected error occurred.";
        if (errorMessage.includes("email")) {
          setError((prev) => ({
            ...prev,
            email: errorMessage,
          }));
        }
        if (errorMessage.includes("website")) {
          setError((prev) => ({
            ...prev,
            website: errorMessage,
          }));
        }
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  return (
    <div className={`free-review-page `}>
      <div className="free-review-wrapper">
        <div className="container">
          <Link to="/conversion-catalyst">
            <div className="review-page-logo">
              <img src={require("..//assets/images/logo.png")} alt="logo" />
            </div>
          </Link>
          {showThankYou ? (
            <div className="thank-you-popup">
              <h2>Thank You For submitting!</h2>
              <p>{thankYouMessage}</p>
              <button
                className="thank-you-btn"
                onClick={() => {
                  setIsOpen(false);
                  navigate("/conversion-catalyst");
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="row audit-popup">
              <div className="col-lg-6 col-md-12 image-section">
                <img
                  src={require("..//assets/images/popup_mockup.png")}
                  alt="Website Audit Illustration"
                />
              </div>
              <div className="col-lg-6 col-md-12 form-section">
                <div className="form-section-description">
                  <h2>GET A FREE WEBSITE AUDIT</h2>
                  <p>
                    Want your website to perform better, convert more visitors,
                    and grow your business? Get a FREE audit and discover hidden
                    opportunities for improvement.
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${
                        error.website ? "is-invalid" : ""
                      }`}
                      name="website"
                      placeholder="Your Website URL"
                      value={formData.website}
                      onChange={handleChange}
                    />
                    {error.website && (
                      <div className="invalid-feedback">{error.website}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control ${
                        error.email ? "is-invalid" : ""
                      }`}
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {error.email && (
                      <div className="invalid-feedback">{error.email}</div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-primary">
                    {loading ? "Submitting..." : "Get My FREE Website Review"}
                  </button>
                  <p style={{ fontSize: "12px", marginTop: "8px" }}>By submitting, you agree to be added to our list to receive valuable information, and exclusive offers.</p>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebReview;
